import axios from '@/assets/js/axios/main_api';
import router from '@/router';
import dayjs from 'dayjs';
import { orderBy, groupBy } from 'lodash';
const errorHandler = (err) => {
	console.error(err.response || err);
	window.$snackbar('There was an error loading the shoot', 'error');
};

const convertHOAHAAFormat = (x, hoaOrHaa) => {
	let o = {};
	Object.freeze(x);
	hoaOrHaa = hoaOrHaa.toUpperCase();

	Object.assign(o, {
		type: hoaOrHaa,
		ShooterId: x.ShooterId,
		ShooterName: x.ShooterName,
		State: x.State,
		SinglesClass: x.Classification,
		DoublesClass: x.Classification,
		StartingSinglesClass: x.Classification,
		Classification: x.Classification,
		ScoreCategory: x.Category,
		Category: x.Category,
		Yardage: 19,
		YardageGroup: 'N/A',
		CategoryId: x.Category,
		SquadNumber: 1,
		Score1: 0,
		Score2: 0,
		Score3: 0,
		Score4: 0,
		Score5: 0,
		Score6: 0,
		Score7: 0,
		Score8: 0,
		Score100_1: x.Score,
		Score100_2: 0,
		Score200: x.Score,
		TotalScore: x.Score,
	});
	return o;
};

export default {
	namespaced: true,
	state: () => ({
		lockoutTimeout: null,
		LockScores: false,
		ScoresLoadCount: 0,
		loaded: false,
		ClubID: '',
		ShootID: '',
		Club: '',
		Scores: {},
		Categories: [],
		Classes: {},
		Events: [],
		HOA: [],
		HAA: [],
		shootoffs: null,
		onDeckBoard: {
			show: false,
		},
	}),
	mutations: {
		SET_LOADED(state, loaded) {
			state.loaded = loaded;
		},
		SETCLUB(store, club) {
			store.ClubID = club.ClubId;
			store.Club = club;
			if (store.Club.ScoreboardSettings)
				if (typeof store.Club.ScoreboardSettings == 'string')
					store.Club.ScoreboardSettings = JSON.parse(
						store.Club.ScoreboardSettings
					);
		},
		SETSHOOTID(store, shootid) {
			store.ShootID = JSON.stringify(shootid);
		},
		SET_SCORES(store, scores) {
			if (scores.length === 0) {
				console.warn('NO SCORES FROM THE SERVER');
				window.$snackbar('No scores to be shown');
				return router.push('/set/shoot');
			}
			scores.forEach((x) => {
				store.Scores[x[0].EvntId] = x;
			});
			store.ScoresLoadCount++;
			store.lockoutTimeout = setTimeout(() => {
				store.LockScores = false;
			}, 5 * 60 * 1000);

			store.LockScores = true;
		},
		SET_CATEGORIES(store, categories) {
			store.Categories = categories;
		},
		SET_CLASSES(store, classes) {
			store.Classes = {
				Singles: classes.filter((c) => c.EvntTypeId === 'Singles'),
				Doubles: classes.filter((c) => c.EvntTypeId === 'Doubles'),
				Handicap: classes.filter((c) => c.EvntTypeId === 'Handicap'),
			};
			for (let index = 0; index < store.Classes.Handicap.length; index++) {
				const grp = store.Classes.Handicap[index];
				grp.Label = `${grp.LowValue} to ${grp.HighValue}`;
			}
		},
		SET_EVENTS(store, events) {
			store.Events = events;
		},
		SET_HOA(store, hoa) {
			store.HOA = hoa;
		},
		SET_HAA(store, haa) {
			store.HAA = haa;
		},
		SET_SHOOTOFFS(store, shootoffs) {
			store.shootoffs = shootoffs;
		},
		RESET_SHOOT(store) {
			store.lockoutTimeout = null;
			store.LockScores = false;
			store.ScoresLoadCount = 0;
			store.loaded = false;
			store.ClubID = '';
			store.ShootID = '';
			store.Club = '';
			store.Scores = {};
			store.Categories = [];
			store.Classes = {};
			store.Events = [];
			store.HOA = [];
			store.HAA = [];
		},
		SET_ODB(store, data) {
			store.onDeckBoard = data;
		},
	},
	actions: {
		async LoadShoot(context, { club, shoot }) {
			context.commit('SETCLUB', club);
			await axios
				.get(`/public/club`)
				.then(({ data }) => {
					context.commit(
						'SETCLUB',
						data.find((c) => c.ClubId === club.ClubId)
					);
				})
				.catch(errorHandler);

			await context.commit('SETSHOOTID', shoot);

			//Load Scores and Events

			const shootid = context.state.ShootID;
			const clubid = club.ClubId;

			if (!shootid || !clubid) {
				console.warn(
					'Tournament Info was attempted to be fetched without a shootid or clubid'
				);
				return;
			}

			//Load Categories & Classes
			const p1 = axios
				.get(`/public/club/${clubid}/shoot/${shootid}/categories`)
				.then(({ data }) => {
					context.commit('SET_CATEGORIES', orderBy(data, 'SuggestedPriority'));
				})
				.catch(errorHandler);
			const p2 = axios
				.get(`/public/club/${clubid}/shoot/${shootid}/classgroups`)
				.then(({ data }) => {
					context.commit('SET_CLASSES', data);
				})
				.catch(errorHandler);
			const p3 = context.dispatch('refreshScores');

			Promise.allSettled([p1, p2, p3]).then(() => {
				context.commit('SET_LOADED', true);
			});
		},
		async refreshScores(context) {
			const isOlderThan30Minutes = (r) => {
				const thirtyMinutesInMilliseconds = 30 * 60 * 1000;

				// Parsing the calcDate string into a Date object
				const calcDate = new Date(r?.results?.calcDate);

				// Checking if calcDate is a valid date object
				if (isNaN(calcDate.getTime())) {
					// If calcDate is not valid, return false
					console.error('Invalid date format');
					return false;
				}

				// Calculating the difference between calcDate and current time in milliseconds
				return Date.now() - calcDate.getTime() >= thirtyMinutesInMilliseconds;
			};

			console.debug('REFRESH SCORES - ', dayjs().format('dddd hh:mm:ss A'));
			if (context.state.LockScores)
				return new Promise((r) => {
					r();
				});
			const shootid = context.state.ShootID;
			const clubid = context.state.ClubID;

			if (!shootid || !clubid) {
				console.warn(
					'Scores were attempted to be fetched without a shootid or clubid'
				);
				return;
			}

			const c1 = axios
				.get(`/public/club/${clubid}/shoot/${shootid}/evnt`)
				.then(({ data }) => {
					context.commit('SET_EVENTS', data);
				})
				.catch(errorHandler);

			const c2 = axios
				.get(`/public/club/${clubid}/shoot/${shootid}/scores`)
				.then(({ data }) => {
					context.commit(
						'SET_SCORES',
						Object.values(
							groupBy(orderBy(data, ['Score200'], ['desc']), 'EvntId')
						)
					);
				})
				.catch(errorHandler);

			const c3 = axios
				.get(`/public/club/${clubid}/shoot/${shootid}/scores/hoa`)
				.then(({ data }) => {
					const hoa = [];
					data.forEach((x) => {
						hoa.push(convertHOAHAAFormat(x, 'HOA'));
					});

					context.commit('SET_HOA', hoa);
				})
				.catch(errorHandler);

			const c4 = axios
				.get(`/public/club/${clubid}/shoot/${shootid}/scores/haa`)
				.then(({ data }) => {
					const haa = [];
					data.forEach((x) => {
						haa.push(convertHOAHAAFormat(x, 'HAA'));
					});

					context.commit('SET_HAA', haa);
				})
				.catch(errorHandler);

			const c5 = axios
				.get(
					`https://altdata.shootpro.cloud/shoot-offs/club/${clubid}/shoot/${shootid}`
				)
				.then(({ data }) => {
					data = data.filter(isOlderThan30Minutes);
					let so = {};
					for (let i = 0; i < data.length; i++) {
						try {
							delete data[i].results.calcInput;
						} catch (e) {
							e;
						}
						so[data[i].eventId] = data[i].results;
						if (
							so[data[i].eventId].type === 'open' &&
							so[data[i].eventId].open.length == 0
						) {
							console.warn(
								`Shootoff has no data for event id = ${data[i].eventId} will skip displaying results`
							);
							delete so[data[i].eventId];
						} else if (
							so[data[i].eventId].type === 'in-out' &&
							so[data[i].eventId].outState.length == 0 &&
							so[data[i].eventId].inState.length == 0
						) {
							console.warn('Shootoff has no data for', data[i].eventId);
							delete so[data[i].eventId];
						}
					}
					context.commit('SET_SHOOTOFFS', so);
				})
				.catch(errorHandler);

			return Promise.all([c1, c2, c3, c4, c5]).then((r) => {
				//If scoreboard type is timer based, start loading ondeckboard details
				if (
					context.state.Club.ScoreboardSettings &&
					context.state.Club.ScoreboardSettings.scoreboardType === 'timer'
				) {
					let found = false;
					for (let index = 0; index < context.state.Events.length; index++) {
						const evt = context.state.Events[index];

						if (evt.SquadOnDeck) {
							context.commit('SET_ODB', {
								show: true,
								popupVariant:
									context.state.Club.ScoreboardSettings.timer.variant ||
									'range',
								lastSquad: evt.SquadOnDeck,
								startingSquad: 1 + (evt.SquadOnDeck - evt.NumberOfBanks),
								bankLabels:
									evt.bankLabels ||
									Array(evt.NumberOfBanks)
										.fill(0)
										.map((x, i) => i + 1),
							});
							found = true;
							break;
						}
					}
					if (!found) {
						context.commit('SET_ODB', {
							show: false,
						});
					}
				}
				return r;
			});
		},
	},
	getters: {
		loaded(state) {
			return state.loaded;
		},
		Club(state) {
			return state.Club;
		},
		scores(state) {
			return state.Scores;
		},
		Categories(state) {
			return state.Categories;
		},
		Classes(state) {
			return state.Classes;
		},
		Events(state) {
			return state.Events;
		},
		HOA(state) {
			return state.HOA;
		},
		HAA(state) {
			return state.HAA;
		},
		ScoresLoadCount(state) {
			return state.ScoresLoadCount;
		},
	},
};
