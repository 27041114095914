<template>
	<v-app class="noOverflow">
		<v-main class="v1 noOverflow" id="maincontainer">
			<v-fade-transition>
				<router-view />
			</v-fade-transition>
			<VuexSnackbar />
		</v-main>
	</v-app>
</template>

<script>
import VuexSnackbar from './components/VuexSnackbar.vue';
export default {
	components: { VuexSnackbar },
	mounted() {
		//Enable window debug if in development
		let devmode = process.env.NODE_ENV === 'development';
		window.enable_debug = {
			announcements: devmode,
			keystrokes: devmode,
		};
		if (window.enable_debug)
			console.info('DEBUG ENABLED BECAUSE DEVELOPMENT MODE');

		this.$store.dispatch('loadFromLocalStorage');

		setTimeout(() => {
			if (this.$route.query.useDev) {
				if (this.$route.query.useDev != 'false') {
					//If the state of devmode is changing reload
					if (!localStorage.useDev) {
						window.$snackbar('Turning on useDev...');
						setTimeout(() => {
							window.location.reload();
						}, 200);
					}
					localStorage.useDev = true;
				} else {
					//If the state of devmode is changing reload
					if (localStorage.useDev) {
						window.$snackbar('Turning off useDev...');
						setTimeout(() => {
							window.location.reload();
						}, 200);
					}
					delete localStorage.useDev;
				}
			}
		}, 1000);
	},
};
</script>

<style>
.v1 {
	/* background: rgb(71, 70, 66); */
}
</style>
<style>
html {
	overflow-y: hidden !important;
}
</style>
