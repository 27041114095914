import axios from 'axios';
let warningGiven = false;
const instance = axios.create({
	baseURL: localStorage.useDev
		? 'https://api-next.shootpro.cloud/'
		: 'https://api.shootpro.cloud/',
});

instance.interceptors.request.use((config) => {
	config.headers['x-from-service'] = 'rolling-scoreboard';
	config.headers['x-rolling-scoreboard'] = 'yes';
	if (config.baseURL.includes('next.')) {
		if (localStorage.useDev) {
			config.headers['x-next-server'] = 'yes';
		}
		console.debug('Using next.api.shootpro.cloud');
		if (!warningGiven) {
			console.warn(
				'You are using next.api.shootpro.cloud. If this is unintentional please remove localStorage.useDev and reload the page.'
			);
			warningGiven = true;
		}
	}
	return config;
});
export default instance;
